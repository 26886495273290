
.App {
  text-align: center;
  display: grid;
  grid-template-rows: 1fr 20fr;
  height: 100%;
width: 100%;
}


#menu{
  background-image: linear-gradient(45deg,  rgba(161,130,98,  0.6),rgba( 130,104,78, 0.5)), url( "background.svg" );

  /*  background-image: linear-gradient(45deg, #a18262 0%, #82684e 50%);*/

  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
 }
 
 #content{
 
   width: 100%;
   height: 100%;
   display: block;
   overflow: hidden;
   overflow-y: scroll;

   background-image: linear-gradient(45deg, rgba( 130,104,78, 0.5), rgba(161,130,98,  1)), url( "background.svg" );
 }
 
 ul {text-align: right; }  
 li {display: inline;
  }
 /* unvisited link */
 a:link {
 text-decoration: none;
}
.menuLink{
  padding: 10px;
  border-radius: 5px;height: 70px;

  
 

}
/* visited link */
a:visited {
  color: white;
  background-color: rgb(153, 80, 24);
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: white;
  background-color: rgb(170, 91, 34);

  text-decoration: none;
}

/* selected link */
a:active {
  color: red;
  background-color: rgb(184, 129, 90);
  text-decoration: none;
} 

 .menuItem{
  color: white;
 border-radius: 5px;height: 70px; margin-left: 20px;
 }

#qouteContainer{
  display: flex;
  background-color: wheat;
}

.js-plotly-plot{
  background-color: rgba(255, 255, 255, 0.122);
}
#qoute{
  /* background-image: linear-gradient(45deg, #a18262 0%, #82684e 50%); */
/*   background-image: url( "backgound.svg" );
 */  background-color: rgba(141, 136, 136, 0.559);

  border-radius: 10px;
  margin-left: 20%;
  width: 50%;
  padding: 2em;
  border-style: dotted;
}

.selector{
  display: grid;
  height: 100%;
  width: 100%;
  padding-top: 5em;
  grid-template-rows: 1fr 1fr 1fr ;

}

.dataRow  { 
  display: flex;
  flex-wrap: wrap;
  padding-top: 5em;
  gap: 100px;
  gap: 100px 200px; /* row-gap column gap */
  row-gap: 10px;
  column-gap: 20px;
  height: 100%;
  overflow: hidden;
}

  .diagramItem{
    flex: 1;
    padding-bottom: 50px;

  }

   .plotItem{
    width: 700px;

  } 

  #days{
    background-color: rgba(255,255,255,0.5);
  }

  .buttonField{
    background-color: rgba(255,255,255,0.5);
    border-radius: 30px;
    box-shadow: 3px 3px 1px 1px rgba(255, 255, 255, .2);
  }
  .h3_diagram{
    margin-top: 0px;
  }

  .buttonLists{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
  }

  .buttonSignals{
    display:grid;
    grid-template-columns: 60px 60px 60px;
    gap: 20px;
  }

  .buttonUsers{
    display:grid;
    grid-template-columns: 2fr 2fr 2fr;
    gap: 20px;
  }